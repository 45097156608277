import {CommandPalettePageElement, PageProps} from './command-palette-page-element'
import {attr, controller, targets} from '@github/catalyst'

export interface Octicon {
  id: string
  svg: string
}

export type OcticonSvgs = {[name: string]: string}
@controller
export class CommandPalettePageStackElement extends HTMLElement {
  @attr currentMode = ''
  @attr currentQueryText = ''
  @targets pages: CommandPalettePageElement[]
  @targets localOcticons: HTMLElement[]

  octiconCache: OcticonSvgs = {}

  get currentPage(): CommandPalettePageElement {
    return this.pages[this.pages.length - 1]
  }

  push(props: PageProps = {}) {
    if (this.hasPageForProps(props)) return

    const page = new CommandPalettePageElement(props)

    this.hideCurrentPages()
    this.append(page)
    page.octicons = this.octiconCache
    page.prefetch()
    this.pageStackUpdated()
  }

  pop() {
    if (!this.currentPage.isRoot) {
      this.removeChild(this.currentPage)
      this.currentPage.reactivate()
      this.pageStackUpdated()
    }
  }

  // Checks if the stack contains a page matching these props.
  // Currently only checks scope ID, other attributes can be added as needed.
  hasPageForProps(props: PageProps): boolean {
    return this.pages.some(page => page.scopeId === props.scopeId)
  }

  hideCurrentPages() {
    for (const page of this.pages) {
      page.hidden = true
    }
  }

  navigate(diff: number) {
    this.currentPage.navigate(diff)
  }

  cacheLocalOcticons() {
    this.addOcticonsToCache(
      this.localOcticons.map(octiconElement => {
        return {id: octiconElement.getAttribute('data-octicon-id'), svg: octiconElement.innerHTML.trim()} as Octicon
      })
    )

    this.currentPage.octicons = this.octiconCache
  }

  addOcticonsToCache(octicons: Octicon[]) {
    for (const octicon of octicons) {
      this.octiconCache[octicon.id] = octicon.svg
    }
  }

  pageStackUpdated() {
    // TODO: dispatch custom event
  }

  connectedCallback() {
    this.setAttribute('data-target', 'command-palette.pageStack')
    this.addEventListener('command-palette-page-octicons-cached', event => {
      if (!(event instanceof CustomEvent)) return
      this.addOcticonsToCache(event.detail.octicons)
    })
    this.cacheLocalOcticons()
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    // forward the current query string and mode to the current page
    if (oldValue !== newValue) {
      switch (name) {
        case 'data-current-mode':
          this.currentPage.mode = this.currentMode
          break
        case 'data-current-query-text':
          this.currentPage.queryText = this.currentQueryText
          break
      }
    }
  }
}
